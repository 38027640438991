import axios from 'axios'
import Vue from 'vue'
import Login from '../login/login'
import { auth, envelopes, bisnode } from "@verified/libvf";

export default {
	name: "ve-dashboard",
	props: {
		sheetname: {
			type: String
		},
		config: {
				type: Object,
				default: function() { return {} }
			},
	},
	data() {
		return {
			error: null,
			translations: null,
			opacity: 0,
			loading: false,
			configuration: {},
			exceptions: [],
			links: [],
			bisnodeError : false,
			recipient : {},
			envelope : null,
    		recipients : [],
			signersList : [],
			errorMsg : null,
			content: {
				hidden: false,
				disabled: false
			},
			authorizedCompanies:['Fiskarhedenvillan AB', 'Fiskarhedenvillan AB - Försäljning', 'Verified Test', 'Sundby Test Inc'],
			logowidth: '300px',
			ud: {
				lang: 'sv_SE',
				translations: {},
				selectedType: null,
				errorMsg : null,
				givenName: null,
				familyName : null,
				email : null,
				contractPurchaseAgreement : [],
		        contractDeliveryDeclaration : [],
		        contractTerms : [],
		        contractIndex : [],
		        additionalOrder : [],
		        productionOrder : [],
	            buildingPermit : [],
	            situationPlan : [],
	            roadMap : [],
		        contractFiles : [],
		        contractFileNames : [],
				owners: [{
					fname : null,
					lname : null,
					address : null,
					zip : null,
					town : null,
					email : null,
					phone : null,
				}]
			},
            user: null,
            selectedType: null
		}
	},
	watch: {
		exceptions: function(newVal) {
			if(newVal.length)
				this.$emit('exception', newVal[newVal.length - 1])
		},

	},
	methods: {
		// Return translation for given key
		__(key) {
			if (this.translations[key])
				return this.translations[key][this.$root.locale]
			return '...'
		},
		// Return config label for given key and language, or value if no label
		__config(key) {
			if(this.configuration[key])
				if(this.configuration[key][this.$root.locale])
					return this.configuration[key][this.$root.locale]
				else
					return this.configuration[key].value
		},
		openUrl(url) { window.open(url) },
		login(user) {
			this.user = user
			setTimeout(() => {
				this.opacity = 1
			}, 10)
			this.$emit("user", this.user)
		},
		logout() {
			localStorage.removeItem("user")
			location.reload()
		},
		exception(e) {
			this.exceptions.push(e)
		},
		
		langTo(lang) {
			this.$root.locale = lang
			this.$forceUpdate()
			this.$emit("language", this.$root.locale)
        },
       	selectType(tab){
       			this.ud.selectedType = tab;
	            this.selectedType = tab
	            console.log(this.user);
	            this.ud.givenName = this.user.givenName
	            this.ud.familyName = this.user.familyName
	            this.ud.email = this.user.email
        },
        handleContractFileChange(e,arrayName) {
            var files = e.target["files"] || e.dataTransfer.files;
            if (arrayName == 'contractPurchaseAgreement') {
              for (var i = 0; i < files.length; i++)
                  this.ud.contractPurchaseAgreement.push(files[i]);
              console.log("contractPurchaseAgreement ====", this.ud.contractPurchaseAgreement);
            }
            
            if (arrayName == 'contractDeliveryDeclaration') {
              for (var i = 0; i < files.length; i++)
                  this.ud.contractDeliveryDeclaration.push(files[i]);
              console.log("contractDeliveryDeclaration ====", this.ud.contractDeliveryDeclaration);
            }

             if (arrayName == 'contractTerms') {
              for (var i = 0; i < files.length; i++)
                  this.ud.contractTerms.push(files[i]);
              console.log("contractTerms ====", this.ud.contractTerms);
            }

             if (arrayName == 'contractIndex') {
              for (var i = 0; i < files.length; i++)
                  this.ud.contractIndex.push(files[i]);
              console.log("contractIndex ====", this.ud.contractIndex);
            }

            if (arrayName == 'additionalOrder') {
              for (var i = 0; i < files.length; i++)
                  this.ud.additionalOrder.push(files[i]);
              console.log("additionalOrder ====", this.ud.additionalOrder);
            }

            if (arrayName == 'productionOrder') {
              for (var i = 0; i < files.length; i++)
                  this.ud.productionOrder.push(files[i]);
              console.log("productionOrder ====", this.ud.productionOrder);
            }
            
            if (arrayName == 'buildingPermit') {
              for (var i = 0; i < files.length; i++)
                  this.ud.buildingPermit.push(files[i]);
              console.log("buildingPermit ====", this.ud.buildingPermit);
            }

             if (arrayName == 'situationPlan') {
              for (var i = 0; i < files.length; i++)
                  this.ud.situationPlan.push(files[i]);
              console.log("situationPlan ====", this.ud.situationPlan);
            }

             if (arrayName == 'roadMap') {
              for (var i = 0; i < files.length; i++)
                  this.ud.roadMap.push(files[i]);
              console.log("roadMap ====", this.ud.roadMap);
            }
            
            this.ud.contractFiles.push(e.target.files[0]);
            console.log("contractFiles ====", this.ud.contractFiles);

            this.ud.contractFileNames.push(e.target.files[0].name);
            this.$emit('input', e.target.files[0])
        },

        removePDFFile(atc=null,arrayName){
          if (arrayName == 'contractPurchaseAgreement') {
             var index = this.ud.contractPurchaseAgreement.indexOf(atc);
             this.ud.contractPurchaseAgreement.splice(index, 1);
          }
          if (arrayName == 'contractDeliveryDeclaration') {
             var index = this.ud.contractDeliveryDeclaration.indexOf(atc);
             this.ud.contractDeliveryDeclaration.splice(index, 1);
          }
          if (arrayName == 'contractTerms') {
             var index = this.ud.contractTerms.indexOf(atc);
             this.ud.contractTerms.splice(index, 1);
          }
          if (arrayName == 'contractIndex') {
             var index = this.ud.contractIndex.indexOf(atc);
             this.ud.contractIndex.splice(index, 1);
          }

          if (arrayName == 'additionalOrder') {
             var index = this.ud.additionalOrder.indexOf(atc);
             this.ud.additionalOrder.splice(index, 1);
          }

           if (arrayName == 'productionOrder') {
             var index = this.ud.productionOrder.indexOf(atc);
             this.ud.productionOrder.splice(index, 1);
          }
          if (arrayName == 'buildingPermit') {
             var index = this.ud.buildingPermit.indexOf(atc);
             this.ud.buildingPermit.splice(index, 1);
          }
          if (arrayName == 'situationPlan') {
             var index = this.ud.situationPlan.indexOf(atc);
             this.ud.situationPlan.splice(index, 1);
          }
          if (arrayName == 'roadMap') {
             var index = this.ud.roadMap.indexOf(atc);
             this.ud.roadMap.splice(index, 1);
          }

            var index = this.ud.contractFiles.indexOf(atc);
            this.ud.contractFiles.splice(index, 1);

          },
          uploadDocuments(){
              // console.log(this.envelope)
              // console.log(this.envelope.data.documents[0].files)
              console.log("in uploadDocuments");
              let promises = []
              var self = this
              var i = 0
              if(self.ud.contractFiles){
                self.ud.contractFiles.forEach(function(partyFile,key) {
                  console.log("key",key);
                    if(key==0){
                      self.ud.fileName = partyFile.name
                      promises.push(self.envelope.documents[0].uploadPDF( partyFile.name, partyFile))
                      
                    }else{
                    promises.push(self.envelope.createDocument({
                        descriptor: {hash: 1},
                        name: partyFile.name, //.replace(".PDF", "").replace(".pdf", "")
                        source: "file"
                      }).then(function() {
                        return self.envelope.reflect();
                      }).then(function() {
                        return self.envelope.documents[key].uploadPDF(partyFile.name, partyFile);
                      }).catch(function(err) {
                        console.log("Error:", err);
                      }))
                    }         
                })
              }
              return Promise.all(promises)
            },
        addOwners(){
			this.ud.owners.push({
					fname : null,
					lname : null,
					address : null,
					zip : null,
					town : null,
					email : null,
					phone : null,
				})
		},
		async fetchSignatoryBisnode(index){
        	var ssn = this.ud.owners[index].ssn.replace(/[\-\s]/g,"");
			if(ssn.length != 12)
			{
				return;
			}
			try{
				this.busy = true
				this.isBisnodeFetched = false		
				let person = await bisnode.sweden.getPersonInfo(ssn);
				let consumer = person.consumer
				console.log(consumer);				
				this.ud.owners[index].fname = consumer.firstName
				this.ud.owners[index].lname = consumer.surname
				this.ud.owners[index].address = consumer.postalAdressLine1
				this.ud.owners[index].zip = consumer.postCode
				this.ud.owners[index].town = consumer.town
			}catch(ex){
				 console.log(ex)
			}
			console.log(this.ud.firstName)
			this.isBisnodeFetched = true
			this.busy = false
		},
		async configureRecipient(fn, ln, em, od, act, lab, nm, sm){
			console.log("inside configureRecipient");
			console.log(fn, ln, em, od, act, lab, nm, sm);
	        var isExist = this.recipients.filter(function(e){
	            return e.email === em && e.order === od;
	        });
	        if(isExist.length>0){
	            return;
	        }
	        this.recipients.push({
	            "givenName": fn,
	            "familyName": ln,
	            "language": this.$root.locale,
	            "signingMethod": sm,
	            "email": em,
	            "order": od,
	            "role": {
	                "action": act,
	                "label": lab,
	                "name": nm
	            }
	        });
	    },
		async configRecipients() {
			console.log("inside configRecipients");
			let self = this;
			console.log("type ==================" + this.ud.selectedType);
			if(this.ud.selectedType == 'contractSale'){
				console.log("inside contractSale recipient add ");

				self.signersList.forEach(function(signatory){
		 			console.log(signatory);
		 			var type = signatory.type;
		 			if(signatory.type == 'contractSale'&& signatory.role == 'approver'){
		 				self.configureRecipient(signatory.firstName,signatory.lastName, signatory.email, 1,"review", "Godkänn avtalet", signatory.role, "bankid-se");
		 			}
		 		});
				
				self.ud.owners.forEach(function(signer) {
					self.configureRecipient(signer.fname, signer.lname, signer.email, 2, "sign", "Signera", "signer", "bankid-se");

				});
			
				//self.configureRecipient("Niclas", "Brandshage", "niclas.brandshage@fiskarhedenvillan.se", 3,"review", "Godkänn avtalet", "approver", "bankid-se");
			}
			if(this.ud.selectedType == 'additionalOrder'){
				console.log("inside additionalOrder recipient add ");
				self.ud.owners.forEach(function(signer) {
					self.configureRecipient(signer.fname, signer.lname, signer.email, 2, "sign", "Signera", "signer", "bankid-se");

				});
			}
			if(this.ud.selectedType == 'manufactureOrder'){
				console.log("inside manufactureOrder recipient add ");

				self.signersList.forEach(function(signatory){
		 			console.log(signatory);
		 			var type = signatory.type;
		 			if(signatory.type == 'manufactureOrder'&& signatory.role == 'approver'){
		 				self.configureRecipient(signatory.firstName,signatory.lastName, signatory.email, 1,"review", "Godkänn avtalet", signatory.role, "bankid-se");
		 			}
		 		});

				//self.configureRecipient("Fiskarhedenvillan", "AB", "(kalkyl@fiskarhedenvillan.se", 1,"review", "Godkänn avtalet", "approver", "bankid-se");
				self.ud.owners.forEach(function(signer) {
					self.configureRecipient(signer.fname, signer.lname, signer.email, 2, "sign", "Signera", "signer", "bankid-se");

				});
			}

			console.log("recipients in configRecipients ============= ",this.recipients );
			return this.recipients;
		},
		validateForm(form) {
			return this.$refs[form].validate()
		},
		async submitData(){
				console.log("inside submitData");
				console.log(this.ud);
				let self = this;

				
				if(!this.validateForm('form1')){
				console.log("return");
					return
				}

				this.loading = true
				let envelope = null
				this.busy = true
				this.ud._isForwarded = true
				var kopare1 ='';
				var kopare2 ='';

				if(self.ud.selectedType == 'contractSale'){var selected = self.ud.translations.contract_sale[self.ud.lang] }
				if(self.ud.selectedType == 'additionalOrder'){var selected = self.ud.translations.additional_order[self.ud.lang] }
				if(self.ud.selectedType == 'manufactureOrder'){var selected = self.ud.translations.manufacture_order[self.ud.lang] }

				self.ud.notifySignESubject = "Fiskarhedenvillan - "+selected+" att signera";
				self.ud.documentSignESubject = self.ud.translations.docSignedSubject[self.ud.lang];
				

				
				console.log("conract files array ====" + self.ud.contractFiles.length);
				if(self.ud.contractFiles.length <1){
					console.log("return");
					this.loading = false
					this.ud.errorMsg = "noFile";
	            	this.errorMsg = "noFile";
					return
				}

				console.log(self.ud.owners.length);
				if(self.ud.owners[0].fname){
					kopare1 = self.ud.owners[0].fname + " " +  self.ud.owners[0].lname
				}
				console.log("kopare1 ========" + kopare1) ;
				if(self.ud.owners.length>2){
					kopare1 = self.ud.owners[1].fname + " " +  self.ud.owners[1].lname
				}
				console.log("kopare2 ========" + kopare2) ;
				this.fileName = "Köpeavtal-" + kopare1 + "-" + kopare2 + ".pdf"

				var recipients = await this.configRecipients();
				console.log("recipients ==========", recipients);
				try {

					console.log(this.ud);
					await envelopes.create('fiskarhedenvillan-sales').then(function (res) {
						envelope = res;
						self.envelope = res;
						console.log("envelope --------------", envelope);
					}).then(function () {
						console.log("before.................");
						return envelope.getBearerToken('/flows/simple-public-flow');
					}).then(function (token) {
						console.log("after.................", token);
						return auth.setToken(token);
					}).then(function (env) {
						return envelope.reflect();
					}).then(function(){	
						let promises = []
					for(var i = 0; i < recipients.length; i++)
						promises.push(envelope.addRecipient(recipients[i]));
						return Promise.all(promises)
					}).then(function(){
						envelope.firstTemplate().setUserData(self.ud)
					}).then(function () {
						return self.uploadDocuments()
					}).then(function() {
						return self.envelope.reflect();
					}).then(function() {
						return self.envelope.firstDocument().put({ name: self.fileName });
					}).then(function () {
						return envelope.publish();
					}).then(() => {
						self.send = true
						location.reload();
					//	window.location.href = self.config.redirect_url.value;
						//setTimeout(()=> window.close(), 5000)
					})
				}
				catch(e) {
					console.log(e)
					this.exceptions.push(new Error("An error occured during envelope submission"))
					this.busy = false
					this.error = true
					self.loading = false
				}


		},

	},

	async created() {
        Vue.use(Login)		
		 this.$root.locale = this.$root.locale || "en_EN"
		 this.sheetname = 'fiskarhedenvillan-sales';
		// this.configuration = this.config
		
		//Get sheets data
		this.mergeLocale({
			"validation.required": {
				en_EN: "Cannot be empty",
				nb_NO: "Påkrevd",
				sv_SE: "Obligatorisk"
			}
		})
		try {
			if(this.sheetname) {
				let [ config, translations, signatories ] = await Promise.all([
					axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/config'),
					axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/translations'),
					axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/signatories')
				])
				this.translations = this.ud.translations = { ...translations.data}
				this.configuration = { ...config.data}

				
					Object.values(signatories.data).forEach((el) => {
						this.signersList.push(el);
					})

				console.log("signersList ============" , this.signersList);

				this.mergeLocale(this.translations)

			}
		} catch(e) {
			this.exceptions.push(new Error("unable to get sheet data. Try refreshing your browser"))
			
			console.warn(" HERE ......... Couldn't find sheet named '" + this.sheetname + "'")
			if(this.configuration.title)
				console.log("Using passed config:", this.configuration)
			else
				console.warn("No config passed, using defaults")
		}
				
		this.loading = false
	}
};