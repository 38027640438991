import axios from 'axios'
import Vue from 'vue'
//import VueClipboard from 'vue-clipboard2'
import Dashboard from '../components/dashboard/dashboard'
import { auth, envelopes, bisnode } from "@verified/libvf";

export default {
	name: 'app',
	data() {
		return {
			qp: [],
			translations: {},
			configuration: {},
			loading: true,
		    exceptions: [],
		    config: null,
		    isLoggedIn: false,
			user: {},
			ud: {
				lang: 'sv_SE',
				translations: {},
				company: {},
				contractFiles : [],
		        contractFileNames : [],
		        firstName :null,
		        lastName : null

			}
		}
	},
	methods: {
		// Return translation for given key
		__(key) {
			if(this.translations[key])
				return this.translations[key][this.$root.locale]
		},
		// Return config label for given key and language, or value if no label
		__config(key) {
			if(this.configuration[key])
				if(this.configuration[key][this.$root.locale])
					return this.configuration[key][this.$root.locale]
				else
					return this.configuration[key].value
		},
		colorLuminance(hex, lum) {
			// Validate hex string
			hex = String(hex).replace(/[^0-9a-f]/gi, '')
			if(hex.length < 6)
				hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
			lum = lum || 0;
			
			// Convert to decimal and change luminosity
			let rgb = '#', c, i
			for(i = 0; i < 3; i++) {
				c = parseInt(hex.substr(i * 2, 2), 16)
				c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16)
				rgb += ('00' + c).substr(c.length)
			}
			return rgb
		},
		formSelected(value) {
		},
		setLanguage(lang) {
			this.$root.locale = lang
			this.setIso(this.$root.locale)
			this.$forceUpdate()
		},
    openUrl(url) { window.open(url) },
	},
	async created() {
	    console.log("App created")
	    Vue.use(Dashboard)
	    //Vue.use(VueClipboard)
			this.sheetname = "fiskarhedenvillan-sales";
			this.$root.locale = 'sv_SE'
			this.setIso("sv_SE")
			
			try {
				this.loadingLists = true


				// Get sheet data
				if(this.sheetname) {
					const [translations, config] = await Promise.all([
						axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/translations'),
						axios.get('https://sheets.web-services.verified.eu/' + this.sheetname + '/config')
					])


			
					this.translations = this.ud.translations = { ...translations.data}
					this.config = config.data
					this.configuration = config.data
					
					this.mergeLocale(this.translations)

					this.mergeLocale({
						"validation.checkbox": {
							en_EN: "Checkbox must be select",
							sv_SE: "krävs"
						}
					})
				}

				//Set page favicon and title
				if(this.__config('favicon'))
					document.getElementById('favicon').href = this.__config('favicon')
				if(this.__config('title'))
					document.title = this.__config('title')				
				
			} catch(e) {
				console.error(e)
				this.exceptions.push(new Error('Unable to get sheet data. Try refreshing your browser.'))
			}
			
			this.loading = false
		}
}
