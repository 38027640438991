<template>
<div class="smooth" :style="{ opacity: loggedIn ? 0 : 1 }">
	<div class="loader"
	     :style="{ opacity: loading ? 1 : 0 }">
		 <img :src="'./loading.gif'" />
	</div>
	
	<div class="container delay"
	     :style="{ opacity: loading ? 0 : 1 }">
		<div class="row">
			<img class="login-logo"
			     :width="logowidth || '400px'"
			     :src="logosrc || './logo.jpeg'"/>
			     <ve-col xs12 m12> <p style="text-align:center;">{{ __('subHeader') }} </p> </ve-col>
		</div>
		<div class="row">
			<ve-col xs1
			        sm3></ve-col>
			<ve-col xs10
			        sm6>
				<div class="headerBar"></div>
				<div class="card border-0 shadow-sm center-card noround-top mb-5">
					<div class="card-body pb-0">
						<ve-form ref="loginForm"
						         @submit.prevent>
							<div class="row">
								<ve-col xs12
								        sm12>
									<ve-form-group>
										<label>{{__('email')}}</label>
										<ve-input v-model="auth.email"
										          rules="required|email"></ve-input>
									</ve-form-group>
								</ve-col>
								
								<ve-col xs12
								        sm12>
									<ve-form-group>
										<label>{{__('password')}}</label>
										<input class="form-control"
										       v-model="auth.password"
										       type="password"
										       required/>
									</ve-form-group>
								</ve-col>
								
								<ve-col xs12
								        sm12>
									<ve-form-group>
										<div class="no-match"
										     :class="{ shown: noMatch}">{{ __('failedToLogIn') }}
										</div>
										<hr>
										<a target="_blank"
										   href="https://app.verified.eu/#/recover-password">{{__('recoverPassword')}}</a>
									</ve-form-group>
								</ve-col>
								
								<ve-col xs12>
									<ve-form-group>
										<button
											type="submit"
											class="btn btn-rounded btn-primary next-btn"
											:class="{loading: busy}"
											:disabled="busy||!auth.email||!auth.password"
											@click.prevent="authenticate(true)"
										>{{ __('authenticate') }}
											<img :src="'./loading.gif'" 
											     :style="{ opacity: busy ? 1 : 0 }">
										</button>
									</ve-form-group>
								</ve-col>
							</div>
						</ve-form>
					</div>
				</div>
			</ve-col>
		</div>
	</div>
</div>
</template>

<script>
	import axios from 'axios'
	import { auth } from '@verified/libvf'
	import Vue from 'vue'
	
	export default {
		name: "ve-login",
		props: {
			logosrc: {
				type: String
			},
			logowidth: {
				type: String
			},
			authtoken: {
				type: String
			},
			authorizedCompanies: {
				type: Array,
				default: function() { return [] }
			}
		},
		data() {
			return {
				auth: {},
				loggedIn: false,
				translations: null,
				noMatch: false,
				exceptions: [],
				loading: true,
				busy: false,
				user: {}
			}
		},
		watch: {
			exceptions: function(newVal) {
				if(newVal.length)
					this.$emit('exception', newVal[newVal.length - 1])
			}
		},
		methods: {
			// Return translation for given key
			__(key) {
				if(this.translations && this.translations[key])
					return this.translations[key][this.$root.locale]
				return '...'
			},
			// Authentication
			async authenticate(authenticationInProgress) {
				this.noMatch = false
				this.busy = true

				if(authenticationInProgress) {
					try {
						this.token = await auth.login({
							email: this.auth.email,
							password: this.auth.password
						})		

						const companies = await auth.getCompanies();
						console.log("companies ====" , companies);
						//Foreach companies
						for(let i = 0; i < companies.length; i++)
							if(this.authorizedCompanies.indexOf(companies[i].name) !== -1)
								this.company = companies[i];
						
					//	if(!this.company)
					//		for(let i = 0; i < companies.length; i++)
					//			if(companies[i].name === "Verified Demo")
					//				this.company = companies[i];
						

						this.continueAuthentication()
						this.loading = true
					} catch(e) {
						console.log(e)
						this.exceptions.push(new Error("An error occured during call to authenticate"))
						this.busy = false
						this.loading = false
						this.noMatch = true
						this.auth.password = undefined
					}
				} else {
					if(localStorage.getItem("user")) {
						this.user = JSON.parse(localStorage.getItem("user"))
						await auth.authenticate({
							token: this.user.token,
							namespace: this.user.namespace
						});
							this.init()	
					} else {
						this.busy = false
						this.loading = false
					}
				}
			},
			async continueAuthentication() {
				try {
					await auth.setToken(this.token)

					console.log("user company ====" , this.company);
					console.log("user company ====" + this.company.id);
					
					const user = await auth.getUserinfo()

					this.user = {
						givenName: user.givenName,
						familyName: user.familyName,
						email: user.email,
						token: this.token,
						namespace: "/companies/" + this.company.id,
						dashboardLink: "https://app.verified.eu/#/dashboard?companyId=" + this.company.id
					}
					localStorage.setItem("user", JSON.stringify(this.user))
					
					await auth.authenticate({
						token: auth.token,
						namespace: this.user.namespace
					})

					this.init()

				} catch(e) {
					console.log(e)
					this.exceptions.push(new Error("An error occured getting user information"))
					localStorage.removeItem("user")
					this.busy = false
					this.loading = false
				}
			},
			init() {
				this.loggedIn = true
				this.busy = false
				this.loading = false
				setTimeout(() => {
					this.$emit('login', this.user)
				}, 500)
			},
		},
		async created() {
			
			// Get sheets data
			try {
				let [login, translations] = await Promise.all([
					axios.get('https://sheets.web-services.verified.eu/fiskarhedenvillan-sales/login')
				])
				this.translations = login.data

				console.log("translations ==============" ,this.translations);
			} catch(e) {
				this.exceptions.push(new Error("Unable to get sheet data. Try refreshing your browser."))
			}
				
		


			this.authenticate(false)
		}
	}
</script>